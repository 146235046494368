import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useLenis } from '@studio-freight/react-lenis'
import { theme } from 'app/theme'
import { Media } from 'app/theme/media'
import { delay } from 'fp-ts/lib/Task'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo, ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
  location: any
  pageContext: any
}

export const Transition = memo(function Transition({
  children,
  location,
  pageContext,
}: Props) {
  const lenisInstance = useLenis()
  const [loaded, setLoaded] = useState(false)

  lenisInstance?.stop()

  if (typeof window !== 'undefined') {
    window.setTimeout(() => window.scrollTo(0, 0), 1000)
  }

  lenisInstance?.start()

  useEffect(() => {
    history.scrollRestoration = 'manual'
  }, [])

  const variants = {
    visible: {
      opacity: 1,
      zIndex: pageContext.type === 'home' ? 10000 : 10002,
      transition: {
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1],
      },
    },
    hidden: {
      opacity: 0,
      zIndex: -1,
      transition: {
        delay: pageContext.type === 'home' ? 3 : 1.4,
        duration: 0.6,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  }

  return (
    <AnimatePresence mode="wait">
      <Main key={location.pathname}>
        <Media lessThan="desktopSmall">
          <SlideIn
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 1 }}
            transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          />
          <SlideOut
            initial={{ scaleY: 1 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          />
        </Media>

        <Media greaterThanOrEqual="desktopSmall">
          <Aside
            className={loaded ? 'loaded' : undefined}
            data-type={pageContext.type === 'home' ? 'home' : 'default'}
            initial="visible"
            animate="hidden"
            exit="visible"
            onAnimationStart={() => {
              setLoaded(true)

              document.getElementById('hero')?.classList.add('loaded')
            }}
            onAnimationComplete={() => setLoaded(false)}
            variants={variants}
          >
            {pageContext.type === 'home' ? (
              <Wrap>
                <Symbol
                  alt="Stazzo Lu Ciaccaru"
                  src="/symbol.png"
                  width="202"
                  height="112"
                />
              </Wrap>
            ) : (
              <Layers>
                <Layer />
                <Layer />
              </Layers>
            )}
          </Aside>
        </Media>

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Wrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const Symbol = styled.img`
  clip-path: inset(0);
  fill: ${({ theme }) => theme.colors.variants.neutralDark2};
  opacity: 0;
`

const Layers = styled.div``

const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: auto;
  left: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:nth-of-type(2) {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    transition-delay: 0.2s;
  }
`

const Aside = styled(motion.aside)`
  width: 100%;
  height: 100svh;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  position: fixed;
  top: 0;
  left: 0;
  &[data-type='home'] {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
  }
  &.loaded {
    ${Wrap} {
      top: 35%;
      transition: 1.5s cubic-bezier(0.85, 0, 0.15, 1);
    }
    ${Symbol} {
      clip-path: inset(0% 0% 100% 0%);
      transition:
        clip-path 0.8s 1.5s cubic-bezier(0.85, 0, 0.15, 1),
        opacity 0.3s 0.3s;
      opacity: 1;
    }
    ${Layer} {
      left: 0;
    }
  }
`

const Style = css`
  width: 100%;
  height: 100vh;
  background: ${theme.colors.variants.neutralDark3};
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 10002;
`

const SlideIn = styled(motion.aside)`
  ${Style}

  transform-origin: bottom;
`

const SlideOut = styled(motion.aside)`
  ${Style}

  transform-origin: top;
`

const Main = styled(motion.main)``
